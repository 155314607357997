<template>
  <div></div>
</template>
<script>

export default {
  mounted () {
    this.$userLogout()
    // location.href = '/login'
  }
}
</script>
